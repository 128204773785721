/*Root Element*/
:root {
  /* Fonts */
  --inter: Inter, sans-serif;
  --bold: InterBold, sans-serif;

  /* Font-Sizes */
  --h1: 3rem; /*48px*/
  --h2: 2.625rem; /*42px*/
  --h3: 2rem; /*32px*/
  --h4: 1.7rem; /*28px*/
  --h5: 1.25rem; /*20px*/
  --h6: 1.125rem; /*18px*/
  --lg: 1.25rem; /* 20px */
  --md: 1rem; /* 16px */
  --small-body: 0.875rem; /*14px*/
  --smallest: 0.75rem; /*12px*/
}
/*Global CSS*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0 auto;
  overflow-x: hidden;
  font-family: var(--inter);
  max-width: 3840px !important;
}
.slick-dots li button:before {
  color: white !important;
  font-size: 10px !important;
  opacity: 1 !important;
  margin-top: 1rem;
}
.slick-dots li.slick-active button:before {
  margin-top: 1rem;
  opacity: 1;
  color: #7e36f3 !important;
  font-size: 12px !important;
}
/*Font Families Import*/
@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter.ttf") format("truetype");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--inter);
}

p,
span {
  font-family: var(--inter);
}

/*Media Queries for fonts*/
@media (min-width: 768px) and (max-width: 1023px) {
  :root {
    --h1: 2.5rem; /*50px*/
    --h3: 1.825rem; /*32px*/
    --h4: 1.25rem; /*20px*/
    --h5: 1.25rem; /*20px*/
    --h6: 1rem; /*16px*/
    --small-body: 0.875rem; /*14px*/
    --smallest: 0.75rem; /*12px*/
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  :root {
    --h1: 2.375rem; /* 38px */
    --h2: 2.125rem; /*34px*/
    --h3: 1.625rem; /* 26px */
    --h4: 1.25rem; /* 20px */
    --h5: 1.125rem; /* 18px */
    --h6: 1rem; /* 16px */
    --small-body: 1rem; /* 16px */
    --smallest: 0.875rem; /* 14px */
  }
}
@media (max-width: 424px) {
  :root {
    --h1: 2rem; /* 32px */
    --h2: 1.75rem; /*28px*/
    --h3: 1.625rem; /* 26px */
    --h4: 1.25rem; /* 20px */
    --h5: 1.125rem; /* 18px */
    --h6: 1rem; /* 16px */
    --small-body: 1rem; /* 16px */
    --smallest: 0.875rem; /* 14px */
  }
}
